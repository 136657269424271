@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --themecolor: none;
  --themefontcolor: none;
  --liteThemecolor: none;
  --liteBackgroundColor: none;
  --liteButtonBackground: none;
  --navtext: #000000;
  --headpadding: 22px 0px;
  --filterSvg: invert(10%) sepia(108%) saturate(6855%) hue-rotate(260deg) brightness(93%) contrast(70%);
  --themefontsize: 12px;
}
@mixin gradientcolor {
  background-color: var(--themecolor);
}
@mixin shadow1 {
  box-shadow: 0px 3px 6px 0 #0000001a;
}

$themecolor: var(--themecolor);
$themefontcolor: var(--themefontcolor);
$liteThemecolor: var(--liteThemecolor);
$liteBackgroundColor: var(--liteBackgroundColor);
$liteButtonBackground: var(--liteButtonBackground);
$navtext: var(--navtext);
$headpadding: var(--headpadding);
$filterSvg : var(--filterSvg);
$themefontsize: var(--themefontsize);


.btn {
  &:active ,
  &:focus ,
  &:hover {
    background-color: $themecolor;
  }
}
