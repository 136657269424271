.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    /* height: 300px; */
}

.page_404 img {
    width: 100%;
    /* margin-top: 200px; */
}

.four_zero_four_bg {
    background-image: url(https://live-others.s3.eu-west-2.amazonaws.com/practitioner/dribbble_1.gif);
    height: 600px;
    background-position: center;
}


.four_zero_four_bg h1 {
    color: #09a79e;
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #09a79e;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -120px;
}

.contant_box_404 h3 {
    color: #09a79e
}

.contant_box_404 p {
    color: #09a79e;
}