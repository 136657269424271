@import "./vars.scss";

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background: #f6f6f6;
}

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
}

a.ant-btn {
  padding-top: initial;
  line-height: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

button {
  border: none;
  box-shadow: none;
}

.disabled {
  color: #c9c9c9 !important;
}

.cmnTable {
  box-shadow: 0 3px 6px #0000000d;

  .table td,
  .table th {
    padding: 0.5rem 1.1rem;
    vertical-align: top;
    border-top: none;
    height: 60px;
    vertical-align: middle;
    border-bottom: none;
  }

  table {
    margin-bottom: 0;
  }

  thead {
    background-color: $themecolor;

    th {
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      vertical-align: middle;

      small {
        font-size: 12px;
        color: #ffffffb0;
      }
    }
  }

  tbody {
    td {
      font-size: 12px;
      color: #1c1b1b;
      font-weight: 500;
    }
  }

  .table-striped tbody tr {
    background-color: #f8f8f8;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
}

.subTable {
  width: 62%;
  margin-left: 313px;
  margin-top: -120px;
  box-shadow: 0 3px 6px #0000000d;

  .table td,
  .table th {
    padding: 0.5rem 1.1rem;
    vertical-align: top;
    border-top: none;
    height: 60px;
    vertical-align: middle;
    border-bottom: none;
  }

  table {
    margin-bottom: 0;
  }

  thead {
    background-color: $themecolor;

    th {
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      vertical-align: middle;

      small {
        font-size: 12px;
        color: #ffffffb0;
      }
    }
  }

  tbody {
    td {
      font-size: 12px;
      color: #1c1b1b;
      font-weight: 500;
    }
  }

  .table-striped tbody tr {
    background-color: #f8f8f8;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
}

.cmnBtn {
  font-size: 12px;
  color: #ffffff;
  padding: 14px 22px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
  background-color: $themecolor;
  border-radius: 2px;
  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
  }

  img {
    margin-right: 10px;
  }
}

.pagination-wrp {
  display: flex;
  align-items: center;
  margin-top: 50px;

  span {
    font-size: 14px;
    display: flex;
    // color: #000000;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0 25px;

    @media (max-width: 767px) {
      margin: 0 10px;
    }
  }

  li {
    a {
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: $themefontcolor;
        background-color: $navtext;
        border-radius: 5px;
      }

      @media (max-width: 767px) {
        font-size: 13px;
        width: 30px;
        height: 30px;
      }
    }
  }
}

.pagination {
  .span {
    background-color: white;
  }

  .active {
    background-color: $themecolor;
    color: $navtext;
  }

  .previous {
    display: flex;
    color: $themefontcolor;
    background-color: white;
    width: 80px !important;
    height: 37px !important;
    justify-content: center !important;
    padding-right: 5px;
    // margin-right: 30px;
    // margin: 0px 30px 0px 30px;
  }

  .next {
    display: flex;
    color: $themefontcolor;
    background-color: white;
    width: 60px !important;
    height: 37px !important;
    justify-content: center !important;
    padding-left: 5px;
    // margin-left: 20px;
    // margin: 0px 30px 0px 30px;
  }
}

// ...........................SIGNIN.............................

.container-wrp {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.signin-wrp {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #fff;
}

.bgPart {
  width: 50%;
  position: relative;
  background-size: cover;
  background-position: right;

  figure {
    max-width: 50%;
    padding: 8%;
  }
}

.formPart {
  position: relative;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 550px;
  width: 40%;
  margin: auto;

  h1 {
    font-size: 31px;
    color: $themefontcolor;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }

  h6 {
    color: #d1c8c8;
    font-size: 13px;
    margin-bottom: 40px;
  }

  .form-label {
    font-size: 13px;
  }

  .signupForm {
    margin-top: 120px;
  }
}

.sign-field {
  input {
    border-radius: 4px;
    font-size: 12px;
    // border: 1px solid $themecolor;
    height: 45px;
  }

  span {
    margin-top: 10px;
    padding: 10px;
  }

  select {
    margin-left: 10px;
  }
}

.language-dropdown {
  width: 25%;
  position: absolute;
  right: 90px;
  font-size: 0.8rem;
  border: none;
  cursor: pointer;
}

.continuebtn {
  font-size: 14px;
  color: #ffffff;
  margin-top: 30px;
  padding: 14px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-weight: 500;
  background-color: $themecolor !important;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
  }
}

.continuebtn-yes {
  font-size: 14px;
  color: #ffffff;
  margin-top: 27px;
  margin-left: 66%;
  padding: 14px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  display: block;
  width: 16%;
  font-weight: 500;
  background-color: $themecolor;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
  }
}

.infobtn-view {
  font-size: 14px;
  color: #ffffff;
  padding: 14px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  background-color: $themecolor;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
  }
}

.continuebtn-no {
  font-size: 14px;
  color: #ffffff;
  margin-top: -46px;
  margin-left: 85%;
  padding: 14px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  display: block;
  width: 16%;
  font-weight: 500;
  background-color: $themecolor;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
  }
}

.width-100px {
  width: 100px !important;
}


.submitbtn {
  font-size: 14px;
  color: #ffffff;
  margin-top: 30px;
  padding: 14px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  display: block;
  width: 100%;
  font-weight: 500;
  background-color: $themecolor !important;
  border-radius: 4px;
}

.expire-wrp {
  display: block;
  margin: auto;
  width: 50%;
  text-align: center;

  .expire-text {
    font-size: 18px;
  }
}

.forgotlink {
  font-size: 12px;
  color: #c9c9c9;
  display: inline-block;
}

.passwordfeild {
  position: relative;

  .eyeicon {
    position: absolute;
    right: 13px;
    top: -7px;
    bottom: 0;
    display: flex;
    cursor: pointer;
    width: 20px;
  }

  .bseyeicon {
    position: absolute;
    right: 11px;
    top: 10px;
    bottom: 0;
    display: flex;
    cursor: pointer;
    width: 20px;
  }
}

.backtologin {
  font-size: 14px;
  color: $themefontcolor;
  font-weight: 500;
  display: inline-block;
  margin-top: 50px;
}

// ...........................SIGNIN.............................

// ...........................HOME.............................

header {
  padding: $headpadding;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 111;
  box-shadow: 0 3px 6px #0000000d;
  background: #ffffff;

  @media (max-width: 767px) {
    padding: 10px 0px;
  }
}

.menuicon {
  background: none;
  margin-left: 10px;
  padding: 0;
  line-height: initial;
}

.sideMenu {
  box-shadow: 0 3px 6px #0000000d;
  width: 255px;
  height: calc(100vh - 79px);
  overflow-x: auto;
  position: fixed;
  top: 79px;
  background: #ffffff;
  z-index: 111;

  .nav-link {
    padding: 10px 25px;
    font-size: $themefontsize;
    min-height: 58px;
    display: flex;
    align-items: center;
    color: #1e1e1e;
    border-left: 5px solid #09a79e00;
    width: 100%;

    svg {
      margin-right: 15px;
    }

    &:hover,
    &.active {
      background-color: $liteBackgroundColor;
      color: $themefontcolor;
      border-left: 5px solid $themecolor;
    }

    .item-arrow {
      margin-left: auto;

      img {
        margin-right: 0px;
      }
    }

    &[aria-expanded="true"] {
      .item-arrow {
        transform: rotateX(180deg);
      }
    }

    img {
      margin-top: -3px;
      margin-right: 15px;
      width: 17px;
    }
  }
}

.menuDropdown {
  .nav-link {
    padding: 0 0px 0 58px;
  }
}

.mainContent {

  // padding: 79px 10px 10px 255px;
  .mainContent-in {
    padding: 30px;
  }
}

.userDetails {
  display: flex;
  align-items: center;

  figure {
    width: 35px;
    height: 35px;
    overflow: hidden;
  }

  h6 {
    font-size: 12px;
    color: #000000;
    font-weight: 500;
    margin-right: 15px;

    @media (max-width: 575px) {
      display: none;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.customDropdown {
  .dropdown-toggle {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0;
  }

  .dropdown-menu {
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 500;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000014;
    border: none;

    .dropdown-item {
      padding: 10px 15px;

      &:focus,
      &:hover {
        background-color: $liteBackgroundColor;
      }
    }
  }
}

.homepagehead {
  margin-bottom: 30px;

  h1 {
    font-size: 25px;
    color: #000000;
    font-weight: 600;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .searchBox-wrp {
    .form-group {
      width: 350px;

      @media (max-width: 767px) {
        width: 100%;
      }

      .searchImg {
        display: flex !important;
        width: 100%;
        margin-top: -34px !important;
        font-size: 98% !important;
        margin-left: 17px !important;
      }
    }

    .valid-feedback {
      display: block !important;
      color: $themecolor;
      margin-top: -31px;
      margin-left: 18px;
      width: 20px;
    }
  }
}

.breadcrumb-wrp {
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #e9ecef00;
    border-radius: 0.25rem;
    font-size: 12px;
    color: #a4a4a4;
    margin-top: 5px;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.filterBtn {
  background-color: #ffffff;
  border-radius: 5px;
  color: $themecolor;
  font-weight: 500;
  width: 70px;
  height: 43px;
  font-size: 13px;
  border: none;
  display: table;
  margin-right: 10px;
  box-shadow: 0 2px 6px #0000000a;
}

.dropdownBtn {
  background-color: $themecolor;
  color: #fff;
  border-radius: 5px;
  width: auto;
  height: 38px;
}

.show-kits {
  background-color: $themecolor;
  color: #fff;
  border-radius: 5px;
  width: auto;
  height: 34px;
}

.addBtn {
  background-color: #ffffff;
  border-radius: 5px;
  width: 210px;
  height: 43px;
  border: none;
  box-shadow: 0 2px 6px #0000000a;
  color: $themefontcolor;
  font-size: 14px;
  font-weight: 500;

  i {
    color: $themefontcolor;
    margin-right: 5px;
  }
}

.addBtn-home {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-bottom: 10px;
}

.searchBox-wrp {
  input {
    border-radius: 4px;
    font-size: 12px;
    height: 43px;
    width: 100%;
    border: none;
    box-shadow: 0 2px 6px #0000000a;
    // background-image: url("../assets/backgroundimages/searchicon.svg");
    // background-repeat: no-repeat;
    background-position: 20px center;
    padding: 0px 2px 0 50px;
    max-width: 100%;

    &::-webkit-input-placeholder {
      color: $themefontcolor;
      font-weight: 500;
    }

    &::-moz-placeholder {
      color: $themefontcolor;
      font-weight: 500;
    }

    &:-ms-input-placeholder {
      color: $themefontcolor;
      font-weight: 500;
    }

    &:-moz-placeholder {
      color: $themefontcolor;
      font-weight: 500;
    }

    .searchImg {
      display: flex !important;
      width: 100%;
      margin-top: -34px !important;
      font-size: 98% !important;
      color: #28a745;
      margin-left: 17px !important;
    }
  }
}

.patientsTable {
  .cmnTable .table {
    margin-bottom: 1rem;

    td,
    th {
      white-space: nowrap;
    }
  }
}

.tableviewLink {
  font-size: 12px;
  color: $themefontcolor;
  font-weight: 500;
  background: none;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

.releasingBtn {
  font-size: 11px;
  color: #ffffff;
  font-weight: 500;
  background-color: #ff5500;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 23px;
  min-width: 90px;
  justify-content: center;

  &.released {
    background-color: #34b15b;
  }

  img {
    margin-right: 8px;
  }
}

.exportBtn {
  color: #ffffff;
  font-weight: 500;
  background-color: #ff5500;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 43px;
  min-width: 90px;
  justify-content: center;
  padding: 0px 10px;

  i {
    margin-right: 8px;
  }
}

.requestBtn {
  font-size: 11px;
  color: #ffffff;
  background-color: #ff8c66;
  font-weight: 500;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 23px;
  width: 130px;
  justify-content: center;

  &.accepted {
    background-color: #04aa6d;
  }

  &.pending {
    background-color: $liteButtonBackground;
  }

  img {
    margin-right: 8px;
  }
}

.requestLink {
  background: none;
  font-size: 12px;
  color: $themefontcolor;
  font-weight: 500;
}

.requestagainLink {
  color: #ff0000;
}

.cmnModal {
  .modal-content {
    border: none;
    max-height: 760px;
  }

  .modal-dialog {
    max-width: 745px;
    width: 98%;
  }

  .modal-header {
    padding: 2rem 2.5rem;
    background: $themecolor;
    align-items: center;

    @media (max-width: 767px) {
      padding: 15px;
    }

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
    height: 15px;
  }

  .modal-body {
    overflow-y: auto;
    padding: 2rem 2.5rem;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  .modal-footer {
    padding: 2rem 2.5rem;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }
}

.modalBody {
  font-size: 13px;

  p {
    font-size: 13px;
  }

  h1 {
    font-size: 13px;
    font-weight: 600;
  }

  h2 {
    font-size: 13px;
    font-weight: 600;
  }

  h3 {
    font-size: 13px;
    font-weight: 600;
  }
}

.trackingModal {
  .modal-content {
    border: none;
  }

  .modal-dialog {
    max-width: 815px;
    width: 98%;
  }

  .modal-header {
    padding: 2rem 1.5rem;
    background: $themecolor;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }
}

.subscriptionModal {
  .modal-content {
    border: none;
  }

  .modal-dialog {
    max-width: 860px;
    width: 98%;
  }

  .modal-header {
    padding: 2rem 1.5rem;
    background: $themecolor;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }
}

.reportaccess_warning {
  .modal-content {
    border: 1px solid $themecolor;
    width: 602px;
    margin-left: 89px;
    margin-top: 263px;
  }

  .modal-dialog {
    max-width: 815px;
    width: 98%;
  }

  .modal-header {
    padding: 1rem 1.5rem;
    background: $themecolor;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }
}

.dob-warning {
  .modal-content {
    border: 1px solid $themecolor;
    max-width: 602px;
    margin-left: 58px;
    margin-top: 263px;
  }

  .modal-dialog {
    max-width: 815px;
    width: 98%;
  }

  .modal-header {
    padding: 1rem 1.5rem;
    background: $themecolor;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }
}

.dob-warning-head {
  background-color: $themecolor;
}

.userModal {
  .modal-content {
    border: none;
    max-width: 650px;
  }

  .modal-dialog {
    max-width: 745px;
    width: 70%;
  }

  .modal-header {
    padding: 2rem 1.5rem;
    background: $themecolor;
    height: 15px;
    align-items: center;
    font-size: 14px;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
    height: 10px;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }
}

.billingKitModal {
  .modal-content {
    border: none;
  }

  .modal-dialog {
    max-width: 98%;
    width: 1210px;
  }

  .modal-header {
    padding: 2rem 1.5rem;
    background: $themecolor;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
  }

  .modal-body {
    font-size: 14px;
    padding: 2rem 1.5rem;
  }
}

.tracking-wrp {
  overflow-x: auto;
  min-width: 250px;

  ul {
    display: flex;
    // margin: 22px 0;
    min-width: 100%;
  }

  li {
    font-size: 13px;
    color: #000000;
    text-align: center;
    width: 25%;

    &.active {
      color: $themefontcolor;
    }
  }
}

.tracking-detail {
  min-width: 182px;
}

.tracking-detail li {
  width: 100% !important;
}

.tracking-detail .status {
  position: relative;
  left: -25% !important;
  padding: 15px 0 !important;
}

.tracking-detail .date {
  position: relative;
  left: 10% !important;
  text-align: left !important;
}

.trackingbar-wrp {
  position: relative;

  .spanwrp {
    position: absolute;
    width: 100%;
    top: -8px;

    span {
      position: absolute;
      content: "";
      border: 3px solid #c9c9c9;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #fff;
      margin: auto;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {}

      &:nth-child(2) {
        left: 32%;
      }

      &:nth-child(3) {
        left: 62%;
      }

      &:last-child {
        left: 97%;
      }

      &.active {
        background-color: $liteButtonBackground;
        border-color: $liteButtonBackground;
      }
    }
  }
}

.trackingbar {
  position: relative;
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  height: 10px;
  margin-top: 9px;

  .trackingstatus {
    height: 100%;
    background-color: $themecolor;
    border-radius: 11px;
  }
}

.st-dispatched {
  .trackingstatus {
    width: 33%;
  }

  .statustypelist {
    li {
      &:nth-last-child(n + 3) {
        color: $themefontcolor;
      }
    }
  }

  .spanwrp {
    span {
      &:nth-last-child(n + 3) {
        background-color: $themecolor;
        border-color: $themecolor;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

.st-received {
  .trackingstatus {
    width: 64%;
  }

  .statustypelist {
    li {
      &:nth-last-child(n + 2) {
        color: $themefontcolor;
      }
    }
  }

  .spanwrp {
    span {
      &:nth-last-child(n + 2) {
        background-color: $themecolor;
        border-color: $themecolor;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

.st-resultReady {
  .trackingstatus {
    width: 100%;
  }

  .statustypelist {
    li {
      color: $themefontcolor;
    }
  }

  .spanwrp {
    span {
      background-color: $themecolor;
      border-color: $themecolor;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.releaseList {
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    text-align: center;
    font-size: 12px;
    color: #000000;
    padding: 0 22px;

    span {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 10px auto;
    }

    &.active {
      color: $themefontcolor;

      span {
        background-color: $themecolor;
      }
    }
  }
}

.releaseModal {
  .modal-footer {
    flex-direction: column;
    align-items: baseline;

    h5 {
      font-size: 14px;
      color: #000000;
      margin-bottom: 15px;
    }
  }
}

.bgBtn {
  background-color: $themecolor !important;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  width: 143px;
  height: 47px;
  border: none;
}

.outerlineBtn {
  border: 1px solid $themecolor;
  color: $themecolor;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  background-color: $themecolor;
  width: 143px;
  height: 47px;
}

// ...........................HOME.............................

// RESPONSIVE
@media (max-width: 1430px) {
  .bgPart {
    width: 55%;
  }

  .formPart {
    width: 45%;
  }

  .formPart-reset {
    width: 45%;
  }
}

@media (max-width: 991px) {
  .formPart {
    padding: 20px 70px;
    min-width: 460px;
    width: 45%;
  }

  .formPart-reset {
    padding: 20px 70px;
    min-width: 460px;
    width: 45%;
  }
}

@media (max-width: 767px) {
  .signin-wrp {
    display: block;
    height: auto;
    min-height: 100vh;
  }

  .signin-wrp-reset {
    display: block;
    height: auto;
    min-height: 100vh;
  }

  .bgPart {
    width: 100%;
  }

  .formPart {
    width: 100%;
  }

  .formPart-reset {
    width: 100%;
  }

  .bgPart figure {
    max-width: 100%;

    img {
      max-width: 250px;
    }
  }

  .formPart {
    padding: 20px 25px;
    min-width: 100%;
    width: 45%;
  }

  .formPart h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }


  .formPart-reset {
    padding: 20px 25px;
    min-width: 100%;
    width: 45%;
  }

  .formPart-reset h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .continuebtn {
    font-size: 16px;
  }

  .mainContent {
    padding: 56px 0px 0px 0px;
  }

  .mainContent .mainContent-in {
    padding: 15px 15px 15px 15px;
  }

  .sideMenu {
    left: -255px;
    z-index: 111;
    top: 0;
    height: 100%;
    transition: left 0.3s;
    padding: 35px 0px 15px 0px;
    border-right: 1px solid #a6a6a6;

    &.open {
      left: 0px;
      transition: left 0.3s;
    }
  }

  .sideMenu-hide {
    left: -35px;
    z-index: 111;
    top: 0;
    height: 100%;
    transition: left 0.3s;
    padding: 35px 0px 15px 0px;
    border-right: 1px solid #a6a6a6;

    &.open {
      left: 0px;
      transition: left 0.3s;
    }
  }

  .sideMenu .nav-link {
    padding: 5px 0;
    font-size: 12px;
    padding-left: 9px !important;
    min-height: 40px;
  }

  .sideMenu-hide .nav-link {
    padding: 5px 0;
    font-size: 12px;
    padding-left: 9px !important;
    min-height: 40px;
  }

  button.menuclosebtn {
    border: none;
    position: absolute;
    top: 0px;
    right: 0;
    background-color: $themecolor;
    height: 25px;
    line-height: 10px;
    color: #fff;
  }
}

@media (max-width: 575px) {}

// RESPONSIVE

// ...........................QUESTIONNAIRE.............................

.customSelect-wrp {
  min-width: 130px;

  .customSelect__control {
    background: none;
    border: none !important;
    min-height: auto;
    box-shadow: none;
  }

  .customSelect__option {
    font-size: 15px;
    color: #000000;
    padding: 15px 12px;
    background-color: transparent;
  }

  .customSelect__menu {
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 500;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000014;
    border: none;
  }

  .customSelect__menu-list {
    padding: 0;
  }

  .customSelect__single-value {
    font-size: 14px;
    font-weight: 600;
    position: static;
    top: auto;
    left: auto;
    transform: none;
    max-width: none;
  }

  .customSelect__indicator {
    padding: 4px;
  }

  .customSelect__option--is-selected {
    color: #1e1e1e;
    background-color: $liteBackgroundColor;
  }

  .customSelect__indicator-separator {
    display: none;
  }
}

.innerpagesHead {
  margin-bottom: 25px;
  border-bottom: 1px solid #c9c9c980;
  padding-bottom: 10px;

  h1 {
    font-size: 25px;
    color: #000000;
    font-weight: 600;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  ul {
    display: flex;
    align-items: center;

    @media (max-width: 565px) {
      flex-direction: column;
      align-items: baseline;
    }

    li {
      font-size: 14px;
      color: #1e1e1e;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-left: 15px;

      &:first-of-type {
        margin-left: 0px;
      }

      @media (max-width: 565px) {
        margin-left: 0px;
      }

      span {
        font-weight: 600;
        margin-left: 5px;
      }

      .form-group {
        margin: 0;
      }

      .customSelect-wrp .customSelect__single-value {
        color: $themefontcolor;
      }

      .customSelect-wrp .customSelect__indicator {
        color: $themefontcolor;
      }
    }
  }
}

.innerpagesFiltration {
  margin-bottom: 25px;
  border-bottom: 1px solid #c9c9c980;
  padding-bottom: 10px;
  margin-top: 20px;

  h1 {
    font-size: 25px;
    color: #000000;
    font-weight: 600;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  ul {
    display: flex;
    align-items: center;

    @media (max-width: 565px) {
      flex-direction: column;
      align-items: baseline;
    }

    li {
      font-size: 14px;
      color: $themefontcolor;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-left: 15px;

      &:first-of-type {
        margin-left: 0px;
      }

      @media (max-width: 565px) {
        margin-left: 0px;
      }

      span {
        color: $themefontcolor;
        font-weight: 600;
        margin-left: 5px;
      }

      .form-group {
        margin: 0;
      }

      .customSelect-wrp .customSelect__single-value {
        color: $themefontcolor;
      }

      .customSelect-wrp .customSelect__indicator {
        color: $themefontcolor;
      }

      h6 {
        color: $themefontcolor;
        font-weight: 500;
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
}

.tabContainer {
  position: relative;
  margin-bottom: 25px;

  ul {
    display: flex;
    overflow-x: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tabItem {
  margin-right: 2px;
  flex-shrink: 0;
  font-size: 13px;
  color: #1e1e1e;
  font-weight: 400;
  margin-right: 25px;
  cursor: pointer;

  &.active {
    color: $themefontcolor;
    font-weight: 600;
  }

  &:last-of-type {
    margin-right: 0px;
  }
}

.tabItem-controls {
  text-align: center;

  button {
    position: absolute;
    top: 0;
    padding: 0 4px;
    line-height: initial;
    background: #f6f6f6;

    &:disabled {
      opacity: 0;
    }

    &.leftBtn {
      left: 0;
    }

    &.rightBtn {
      right: 0;
      left: auto;
    }
  }
}

.qstnsTable {

  td,
  th {
    border-right: 1px solid #c9c9c999;
  }

  .cmnTable {
    position: relative;
  }

  @media (min-width: 768px) {
    td:first-child {
      width: 265px;
      position: absolute;
      left: 0;
    }

    th:first-child {
      width: 265px;
      position: absolute;
      left: 0;
      background: $themecolor;
    }

    .table-striped tbody tr:nth-of-type(odd) {
      td {
        background-color: #fff;
      }
    }

    .table-responsive {
      width: auto;
      margin-left: 265px;
    }

    table {
      border-collapse: initial;
      border-spacing: 0;

      th {
        white-space: nowrap;
      }
    }
  }

  @media (min-width: 1200px) {
    td:first-child {
      width: 500px;
    }

    th:first-child {
      width: 500px;
    }

    .table-responsive {
      margin-left: 500px;
    }
  }

  @media (min-width: 1600px) {
    td:first-child {
      width: 800px;
    }

    th:first-child {
      width: 800px;
    }

    .table-responsive {
      margin-left: 800px;
    }
  }
}

.customCheckbox {
  margin: 15px 0;

  .checklabel {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
  }

  .checklabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid $themecolor;
    border-radius: 2px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checklabel input:checked~.checkmark:after {
    display: block;
  }

  .checklabel .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid $themecolor;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
}

// ...........................QUESTIONNAIRE.............................

// ...........................DNA RESULT.............................

.colorIndicators {
  white-space: nowrap;
  display: inline-flex;

  span {
    position: relative;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 12px;

    &.active {
      border: 2px solid #1e1e1e;
    }
  }

  &.indicatorCount {
    span {
      &.active {
        &::after {
          content: attr(data-count);
          position: absolute;
          right: 0;
          left: 0;
          top: 13px;
          color: #000000;
          font-size: 12px;
          z-index: 1;
          font-weight: 600;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}

.colorIndicators div div {
  margin-right: 10px;
}

.DnaReporttable {
  .cmnTable {
    td {
      font-size: 12px;
      color: #1e1e1e;
      font-weight: 500;
      white-space: nowrap;

      @media (max-width: 767px) {
        white-space: initial;
      }

      &:first-child {
        color: #1e1e1e;
      }

      a {
        color: $themefontcolor;
      }

      &:last-child {
        // width: 50%;
        text-align: right;
        padding-right: 45px;

        @media (max-width: 767px) {
          width: auto;
        }
      }
    }

    th {
      color: white;
      font-weight: 700;
      font-size: 14px;

      &:last-child {
        // width: 50%;
        text-align: right;
        padding-right: 70px;

        @media (max-width: 767px) {
          width: auto;
        }
      }
    }
  }
}

.selectionBar {
  background-color: $themecolor;
  padding: 15px 20px;
  margin-bottom: 10px;

  .customSelect__single-value {
    color: #fff;
  }

  .customSelect__indicator {
    color: #fff;
  }

  .customSelect__value-container {
    justify-content: flex-end;
  }

  h3 {
    font-size: 15px;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
}

.risktype {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 8px 15px;
  margin-left: 20px;
  display: inline-block;
}

.geneslabel {
  font-size: 12px;
  color: #1e1e1e;
  font-weight: 600;
  background-color: $liteThemecolor;
  border-radius: 3px;
  display: inline-block;
  padding: 7px 15px;
  margin-bottom: 10px;

  &+.geneslabel {
    margin-left: 15px;
  }
}

.dnareportModal {
  font-size: 13px;
  color: #1e1e1e;
  font-weight: 400;

  .modal-dialog {
    max-width: 1200px;
    width: 98%;
  }

  div {
    text-align: justify;
  }

  p {
    text-align: justify;
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 400;
  }

  h6 {
    font-size: 13px;
    color: $themefontcolor;
    font-weight: 600;
    margin: 18px 0;
  }

  h5 {
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 600;
    margin: 18px 0;
  }
}

.genotypeModal {
  font-size: 13px;
  color: #1e1e1e;
  font-weight: 400;

  .modal-dialog {
    max-width: 600px;
    width: 98%;
  }

  div {
    text-align: justify;
  }

  p {
    text-align: justify;
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 400;
  }

  h6 {
    font-size: 13px;
    color: $themefontcolor;
    font-weight: 600;
    margin: 18px 0;
  }

  h5 {
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 600;
    margin: 18px 0;
  }
}

// ...........................DNA RESULT.............................
// ...........................EPIGENETIC RESULT.............................

.reportfilters-wrp {
  margin: 15px 0;
}

.select-field {
  label {
    font-size: 10px;
    color: #1e1e1e80;
  }

  .customSelect__value-container {
    padding: 0;
  }
}

.epigeneticReporttable {
  .cmnTable {
    td {
      font-size: 12px;
      color: #1e1e1e;
      font-weight: 500;
      white-space: nowrap;

      @media (max-width: 767px) {
        white-space: initial;
      }

      // &:nth-child(2) {
      //   color: #1e1e1e;
      // }
      a {
        color: $themefontcolor;
      }

      // &:nth-child(5) {
      //   color: #1e1e1e;
      // }
      &:last-child {

        // width: 50%;
        // text-align: right;
        @media (max-width: 767px) {
          width: auto;
        }
      }
    }

    th {
      font-size: 14px;
      color: white;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}

.sHealthy {
  color: #1e1e1e;
  font-weight: 600;
}

.sUnhealthy {
  color: #fc1504;
  font-weight: 600;
}

.linechart-wrp {
  background-color: #fff;
  height: 180px;
}

.epigeneticgraphModal .modal-dialog {
  max-width: 650px;
  width: 98%;
}

.linechart-wrp {
  width: 100%;
}

.graphCard {
  border: 1px solid $themecolor;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 10px;

  h6 {
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 500;

    .shealthy {
      color: $themefontcolor;
    }
  }

  h3 {
    font-size: 19px;
    color: $themefontcolor;
    font-weight: 600;
  }
}

// ...........................PRACTITIONER REPORT.............................

.reportSummary-wrp {
  padding: 20px;
  background: #fff;

  h6 {
    color: $themefontcolor;
    font-size: 14px;
    color: $themefontcolor;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  p {
    font-size: 13px;
    color: #1e1e1e;
  }

  hr {
    margin: 25px 0;
  }

  canvas {
    height: 280px !important;
    width: 280px !important;
  }
}

.reportDetail-wrp {

  th,
  td {
    &:not(:first-child) {
      text-align: center;
    }
  }

  td {
    &:last-child {
      width: 25%;
      text-align: left;
      padding-left: 70px;

      @media (max-width: 767px) {
        width: auto;
      }
    }
  }
}

.linkStyle a {
  color: #09a79e;
}

.disabledLink a {
  pointer-events: none;
}

.summary-title {
  color: #09a79e;
}

.summary-text {
  font-size: 13px;

  p {
    padding-top: 10px;
    text-align: justify;
  }

  ul {
    padding-left: 35px;
  }
}

.disease-risk.summary-text {
  p {
    padding-top: 0px;
  }
}

.congenital-summary {
  display: flex;
  align-items: center;

  .left-part {
    flex: none;
  }

  .right-part .summary-heading {
    font-size: 15px;
    font-weight: 500;
  }
}

.result-comment {
  font-size: 13px;

  p {
    padding-top: 5px;
    text-align: justify;
    color: #000;
  }
}

.practitionerselect {
  min-width: 180px;

  .customSelect__option {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      // background-image: url("../assets/backgroundimages/summaryicon.svg");
      width: 15px;
      height: 15px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .customSelect__menu {
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 500;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000014;
    border: none;
  }

  .customSelect__menu-list {
    padding: 0;
  }

  .customSelect__single-value {
    font-size: 14px;
    font-weight: 600;
    position: static;
    top: auto;
    left: auto;
    transform: none;
    max-width: none;
  }

  .customSelect__indicator {
    padding: 4px;
  }

  .customSelect__option--is-selected {
    color: #1e1e1e;
    background-color: $liteBackgroundColor;
  }

  .customSelect__indicator-separator {
    display: none;
  }
}

// ...........................PRACTITIONER REPORT.............................

.loader-wrp {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Validation text for form */
.text-muted {
  color: red !important;
}

.alert {
  margin-bottom: 0px;
}

// ...........................LIFE STYLE DATA.............................

.overviewcard-wrp {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 3px 6px #0000001a;
  margin-bottom: 30px;

  .overviewHead {
    text-align: center;
    padding: 20px 25px;
    height: 290px;

    h3 {
      font-size: 16px;
      color: #1e1e1e;
      font-weight: 500;
      text-align: left;

      small {
        font-size: 12px;
        color: #c9c9c9;
        margin-left: 8px;
        display: none;
      }
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $themecolor;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      margin: 25px auto;
    }

    h4 {
      font-size: 15px;
      color: $themefontcolor;
      font-weight: 500;
    }

    ul {
      display: flex;
      justify-content: center;
      margin-top: 15px;

      li {
        font-size: 12px;
        color: #1e1e1e;
        font-weight: 500;
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin: 10px 0 0px 0;

        &:not(:first-child) {
          border-left: 1px solid #c9c9c9;
        }

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .overviewDetails {
    border-top: 1px solid #09a79e2b;
    padding: 25px 20px;
    padding-bottom: 0px;
    display: none;
    height: 200px;

    h5 {
      font-size: 13px;
      color: $themefontcolor;
      font-weight: 500;
      margin-bottom: 25px;
    }

    li {
      font-size: 13px;
      color: #1e1e1e;
      font-weight: 500;
      margin: 10px 0;

      span {
        font-weight: 600;
      }

      img {
        margin-right: 8px;
        position: relative;
        top: -1px;
      }
    }
  }
}

.activeTimeDetails {
  list-style: none;

  li {
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 500;
    background-color: $liteThemecolor;
    height: 45px !important;
    border-radius: 8px;
    padding-top: 12px;
    padding-left: 17px;
    text-align: initial;
    margin: 20px !important;

    span {
      font-weight: 600;
    }

    img {
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
  }
}

.activetimedetails {
  li {
    background-color: #09a79e26;
    border-radius: 5px;
    padding: 15px;
  }
}

.overviewdetails-wrp {
  .overviewDetails {
    display: block;
  }

  .overviewcard-wrp {
    margin-bottom: 0;

    @media (max-width: 1199px) {
      margin-bottom: 20px;
    }
  }

  .overviewcard-wrp .overviewHead h3 small {
    display: inline-block;
  }
}

.graph-wrp {
  padding: 20px;
  background: #fff;
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 5px;

  h5 {
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 500;
    margin-bottom: 25px;
  }
}

.graphfilter {
  margin-bottom: 15px;
}

.activityfilter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-size: 11px;
    color: #1e1e1e;
    font-weight: 500;
    margin: 0;
  }

  .customSelect-wrp .customSelect__single-value {
    color: $themefontcolor;
    font-size: 11px;
  }

  .customSelect-wrp .customSelect__indicator {
    color: $themefontcolor;
  }
}

.dwmy-list {
  background-color: $liteBackgroundColor;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;

  li {
    position: relative;
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 2px 20px;

    &.active {
      background-color: $themecolor;
      color: #fff;
    }

    &:not(:first-child) {
      &::before {
        content: "";
        width: 1px;
        height: 10px;
        background-color: $themecolor;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }
    }
  }

  button {
    &.active {
      background-color: $themecolor;
      color: #fff;
    }
  }
}

.iconlist {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  li {
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 500;

    &:not(:last-child) {
      margin-right: 15px;
    }

    img {
      margin-right: 10px;
    }

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }
}

.cmnchart-wrp {
  @media (min-width: 1200px) {
    height: 397px;
    width: 100%;

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

// ...........................LIFE STYLE DATA.............................

.loader-wrp {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ...........................MANAGEMENT INSIGHTS.............................

.textcolor-green {
  color: #34b15b;
}

.textcolor-red {
  color: #fc1504;
}

.managamentInsight-wrp {
  .graph-wrp {
    margin-bottom: 30px;
  }

  .cmnchart-wrp {
    height: 339px;
    width: 100%;
  }

  .cmnTable {
    margin-bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
  }
}

.cmnCard {
  box-shadow: 0 3px 6px #0000000d;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;

  .cmncardHead {
    background-color: $themecolor;
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.1rem;

    h4 {
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
    }
  }

  .cmncardBody {
    padding: 0.5rem 1.1rem;
  }
}

.squareList {
  li {
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    margin: 12px 0;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: $themecolor;
      margin-right: 10px;
    }
  }
}

.filterBox {
  .dropdown-menu {
    padding: 0;
  }
}

.dropdown-toggle.filterbtn {
  background-color: #ff000000 !important;
  font-size: 15px;
  color: #09a79e !important;
  font-weight: 700;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;

  &:after {
    display: none;
  }
}

.filterContent {
  background: #fff;
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 5px;
  padding: 25px;
  width: 420px;

  @media (max-width: 565px) {
    width: 280px;
    padding: 15px;
  }

  label {
    font-size: 10px;
    color: #1e1e1e;
    font-weight: 500;
  }

  button {
    width: 122px;
    height: 38px;

    &.bgBtn {
      margin-left: 10px;
    }
  }
}

.customSelect-wrp2 {
  .customSelect__control {
    border-color: #09a79e;
    border-radius: 3px;
  }

  .customSelect__indicator {
    color: #09a79e;
  }

  .customSelect__indicator-separator {
    display: none;
  }

  .customSelect__placeholder {
    display: none;
  }
}

// ...........................MANAGEMENT INSIGHTS.............................

.loader-wrp {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmnTable1 {
  box-shadow: 0 3px 6px #0000000d;

  .table td,
  .table th {
    padding: 0.5rem 1.1rem;
    vertical-align: top;
    border-top: none;
    height: 60px;
    vertical-align: middle;
    border-bottom: none;
  }

  table {
    margin-bottom: 0;
  }

  thead {
    background-color: $themecolor;

    th {
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      vertical-align: middle;

      small {
        font-size: 12px;
        color: #ffffffb0;
      }
    }
  }

  tbody {
    td {
      font-size: 12px;
      color: #1c1b1b;
      font-weight: 500;
    }
  }

  .table-striped tbody tr {
    background-color: #f8f8f8;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
}

// ...........................USER MANAGEMENT.............................
.selectionBar-admin {

  // background-color: $themecolor;
  // position: relative;
  // display: inline-flex;
  // justify-content: space-between;
  // width: 100%;
  .customSelect__single-value {
    color: #000;
  }

  .customSelect__indicator {
    color: #000;
  }

  .customSelect__value-container {
    // justify-content: flex-end;
    position: relative;
    padding-right: 10px;
  }

  h3 {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
  }
}

.tableviewLink img {
  height: 20px;
  width: 20px;
}

.addButton {
  // margin: 5px;
  // background-color: #09a79e;
  // .releasingBtn {
  font-size: 14px;
  color: $themefontcolor;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 35px;
  min-width: 90px;
  justify-content: center;

  &.released {
    background-color: #34b15b;
  }

  img {
    margin-right: 8px;
  }

  // }
}

.confirm-button-yes {
  background-color: $themecolor;
  border-color: $themecolor;
  margin: 5px;
}

.confirm-button-no {
  background-color: $themecolor;
  border-color: $themecolor;
  margin: 5px;
}

.open-filter {
  padding: 15px;
  background-color: white;
  color: #000;
  position: absolute;
  top: 120%;
  left: 4%;
  z-index: 1000;
}

.partnerselect {
  margin-bottom: 10px;
}

.clinicselect {
  margin-bottom: 10px;
}

.pratitionerselect {
  margin-bottom: 10px;
}

.close-filter {
  display: none;
}

.user-management-modal {
  overflow: auto;
  max-height: 800px;
}

.multiselect-patients {}

.select-language {
  width: auto;
}

.list-unstyled-um {
  -webkit-columns: 3;
  list-style: none;
}

.chip {
  background: $themecolor !important;
}

.filterDatePicker {
  .react-datepicker-wrapper input {
    display: none;
  }

  .react-datepicker-popper {
    min-width: 200px;
  }
}

.select-options {
  width: 250px;
}

// Check box
.customCheckbox input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 13px;
  height: 13px;
  /* background-color only for content */
  background-clip: content-box;
  border: 0.5px solid #bbbbbb;
  border-radius: 2px;
  background-color: #e7e6e7;
  margin-left: 10px;
  margin-right: 5px;

  &:checked {
    background-color: $themecolor;
    background-image: url("../assets/backgroundimages/statuschecked.svg");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &:focus {
    outline: none !important;
  }
}

//Radio button
.customRadio input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 0.5px solid white;
}

.italicText {
  font-style: italic;
}

input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $themecolor;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.radioForm {
  margin-left: 25px;
  margin-top: -26px;
}

.header-fixed {
  position: fixed;
  width: -webkit-fill-available;
  z-index: 1;
}

.pagination {
  cursor: pointer;
}

.pagination-previous {
  background: white;
  padding: 7px;
  border-radius: 5px;
  color: $themefontcolor;

  span {
    color: $themefontcolor;
  }
}

.pagination-next {
  background: white;
  padding: 7px;
  border-radius: 5px;
  color: $themefontcolor;

  span {
    color: $themefontcolor;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.search-close {
  background-color: white;
  padding: 7px 10px 7px 10px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 15px;
  font-weight: 600;
  color: hsl(0, 0%, 50%);
}

.reference-link {
  color: $themecolor;
}

.sideMenuIcons {
  margin-right: "15px";
}

.filterit {
  filter: $filterSvg;
}

.spinnerClass {
  color: $liteButtonBackground !important;
}

.trackingNode {
  background-color: $themecolor !important;
  z-index: 10 !important;
  border-radius: 130px !important;
  border: 2px solid $themecolor !important;
}

// ...........................BUSINESS INFORMATION.............................
// ...  User ...

.businessinfo-user-wrp {
  .graph-wrp h5 {
    font-size: 16px;
  }

  .cmnchart-wrp {
    height: 437px;
    // height: 373px;
    width: 100%;

    @media (max-width: 991px) {
      height: auto;
    }
  }
}

.userRegisteredcard {
  background: #fff;
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;

  h2 {
    font-size: 16px;
    color: #1e1e1e;
    font-weight: 500;
    margin-bottom: 25px;
  }

  ul {
    display: flex;
    align-items: center;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: initial;
    }

    li {
      &:first-child {
        font-size: 12px;
        color: $themecolor;
        font-weight: 500;
        margin-right: 15px;

        @media (max-width: 575px) {
          margin-right: 0px;
          margin-bottom: 15px;
        }

        span {
          font-weight: 600;
        }

        img {
          margin-right: 15px;
        }
      }

      &:nth-child(2) {
        font-size: 13px;
        color: #1e1e1e;
        font-weight: 500;
        border-left: 1px solid #c9c9c9;
        padding-left: 15px;

        @media (max-width: 575px) {
          border-left: none;
          padding-left: 0px;
        }

        span {
          font-weight: 600;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }
}

.doughnutchart-wrp {
  width: 210px;
  height: 210px;

  @media (max-width: 1300px) {
    width: 170px;
    height: 193px;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.doughnutCard {
  background: #fff;
  box-shadow: 0 3px 6px #0000001a;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;

  h5 {
    font-size: 16px;
    color: #1e1e1e;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .doughnutcardBody {
    display: flex;
    align-items: center;

    @media (max-width: 575px) {
      flex-direction: column;
    }

    &.stacked {
      flex-direction: column;

      .doughnutdetails {
        margin-left: 0;
        width: 100%;

        ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 25px;

          li {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.doughnutdetails {
  margin-left: 20px;

  @media (max-width: 575px) {
    margin-left: 0px;
  }

  ul {
    display: flex;
    align-items: center;
  }

  li {
    font-size: 13px;
    color: #1e1e1e;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-right: 15px;

    span {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 10px;
      flex: none;
    }

    &:first-child {
      margin-right: 15px;
    }
  }
}

.percentagedetails {
  background-color: $liteBackgroundColor;
  border-radius: 5px;
  font-size: 12px;
  color: $themecolor;
  font-weight: 500;
  padding: 15px 15px;
  margin-bottom: 10px;

  img {
    margin-right: 8px;
  }
}

// ...  User ...

// ...  Tracking ...
.businessinfo-tracking-wrp {
  .overviewcard-wrp .overviewHead {
    padding: 33px 20px;

    @media (max-width: 1300px) {
      padding: 24px 25px;
    }
  }

  .overviewcard-wrp .overviewHead h3 {
    min-height: 42px;
  }
}

// ...  Tracking ...

// ...  Engagement ...

.businessinfo-engagement-wrp {
  .graph-wrp {
    margin-bottom: 25px;
  }

  .linechart-wrp {
    height: 397px;

    @media (max-width: 1199px) {
      height: 380px;
    }

    @media (max-width: 991px) {
      height: auto;
    }
  }

  // ...  Engagement ...
}

// ... Intro ...

.intro-text {
  text-align: justify;
  white-space: break-spaces;
  word-spacing: normal;
  text-justify: inter-word;
}

//  .... Pie chart ....
.piechart-container {
  cursor: pointer;
  padding: 10px 10px 10px 10px;
}

.piechart-block {
  display: flex;
}

.piechart-block-div {
  margin: auto;
}

// Management insight
.pie-chart-overview {
  cursor: pointer;
  width: 300px;
  height: 200px;

  @media (max-width: 1199px) {
    width: 300px;
    height: 200px;
  }

  @media (max-width: 991px) {
    width: 200px;
    height: 150px;
  }
}

.trackingModalPdf {
  .modal-content {
    border: none;
  }

  .modal-dialog {
    max-width: 745px;
    width: 98%;
  }

  .modal-header {
    // padding: 2rem 1.5rem;
    background: $themecolor;

    .close {
      padding: 0;
      margin: 0;
      width: 19px;
      height: 19px;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      color: $themefontcolor;
      font-weight: 300;
      font-size: 21px;
    }
  }

  .modal-title {
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
  }

  .modal-body {
    padding: 2rem 1.5rem;
  }
}

.training-pdf-modal {

  // text-align: center;
  button {
    background-color: $liteButtonBackground;
    border-color: $liteButtonBackground;
    border-radius: 5px;
    margin: 10px;
    color: white;
  }

  // p {
  //   color: black;
  // }
  .react-pdf__Page {
    // margin-left: 8%;
    overflow: auto;
    // canvas {
    //   width: 100% !important;
    //   height: auto !important;
    // }
  }

  .pdf-controls {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 10px;
      color: black;
    }
  }
}

.customSelect__menu {
  width: 125% !important;
  margin-right: 200px;
}

.headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  /*only relevant for first row*/
  margin-top: -1px;
  /*compensate for top border*/
}

.long {
  position: relative;
}

.headcol:before {
  content: "Row ";
}

.section {
  padding: 80px 10px 10px 255px;

  @media (max-width: 767px) {
    padding: 80px 10px 10px 0px;
  }
}

.section-alert {
  padding: 130px 10px 10px 255px;

  @media (max-width: 767px) {
    padding: 130px 10px 10px 0px;
  }
}

.partner-select {
  display: flex;
  align-items: baseline;

  p {
    color: black !important;
    padding-right: 5px;
  }
}

.MuiChip-deleteIcon {
  color: beige !important;
}

.MuiChip-deletable {
  background-color: $themecolor !important;
  color: white !important;
  margin-right: 5px;
  margin-bottom: 10px;
}

.tracking-history {
  display: inline-flex;
}

.tracking-history-form {
  padding-left: 10px;
  margin-top: 3px;
}

.subscription-form {
  margin-top: 5px;
  width: 233px;
  margin-left: 23px;
}

.subscription_text {
  display: block;
  margin-top: 0.25rem;
  margin-left: 27px;
  font-size: 0.875em;
  font-weight: 500;
}

/* ProgressBar.css */

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f1f1f1;
  /* Background color for the progress bar container */
  border-radius: 4px;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  border-radius: 4px;
}

.txt-center {
  text-align: center;
}

/* Styles for the arrow buttons */
.sort-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 11px;
}

/* Styles for the upward arrow */

/* Styles for the downward arrow */
.sort-btn.desc {
  transform: rotate(180deg);
}

.selectedSort {
  color: grey !important;
}

.withUpwardDownardArrow div {
  display: inline-table;
}

.sideMenuIcons {
  svg {
    path {
      fill: $themecolor;
    }
  }
}

.sideMenuIcons1 {
  svg {
    path[fill="#09A79E"] {
      fill: $themecolor;
    }
  }
}

.epiReportIcons {
  svg {
    path {
      fill: $themecolor;
    }
  }
}

.termsRadioBtn {
  line-height: 10px;
}

@media (min-width: 556px) and (max-width: 991.98px) {

  .fontSizeReport {
    font-size: 13px;
    padding-bottom: 20%;
  }
}

@media (min-width: 991.98px) {

  .fontSizeReport {
    font-size: 11px;
  }
}

.signup-radio input[type="radio"]:checked::after {
  top: 15px;
  /* Adjust this value to change the top position */
}

.formPart-reset {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 550px;
  width: 40%;
  margin: auto;

  h1 {
    font-size: 31px;
    color: $themefontcolor;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }

  h6 {
    color: #d1c8c8;
    font-size: 13px;
    margin-bottom: 40px;
  }

  .form-label {
    font-size: 13px;
  }

  .signupForm {
    margin-top: 120px;
  }
}

.user-header-reset {
  padding: 10px 10px;
  position: relative;
  width: 100%;
  left: 0;
  z-index: 111;
  box-shadow: 0 3px 6px #0000000d;
  background: #ffffff;
  overflow: hidden;
}

.signin-wrp-reset {
  height: 100vh;
  overflow: scroll;
  background: #fff;
}

.disclaimer-container {
  max-height: 500px;
  overflow-y: auto;
  color: black;
  line-height: 35px;
  padding: 5px;
}

.disclaimer-container h4 {
  margin-bottom: 20px;
}

.disclaimer-container p {
  color: black;
}

// .disclaimer-container p.bold {
//   font-weight: bold;
// }